import { RouterProvider } from "react-router-dom";
import { router } from "./routes/Route";

function App() {

  return (
    <div className="relative font-lato">
      <RouterProvider router={router} />
    </div>
  );
}

export default App;
