import React from 'react'
import { Link } from 'react-router-dom'
// import homeServicesCard1 from "../../assets/home/homeServicesCard1.svg"

export default function HomeServicesCard({ img, heading, description, path }) {
    return (
        <div className='flex flex-col items-start justify-between gap-[0.5rem] bg-white w-[310px] xs:w-[400px] h-[280px] rounded-[14px] p-[1rem] overflow-y-auto'>
            <div className=''>
                <img src={img} alt="" className='w-[110px] h-[65px] object-cover rounded-[10px]' />
            </div>
            <div>
                <h2 className='text-[1.2rem] sm:text-[1.4rem] font-bold'>
                    {heading}
                </h2>
                <p className='text-[#8888A3] my-[0.5rem] text-[0.9rem] xs:text-[1rem]'>
                    {description}
                </p>
            </div>
            <Link to={`${path}`}>
                <button className='text-primary font-[500] border border-primary px-[1rem] py-[0.3rem] rounded-[18px] hover:text-white hover:bg-primary'>
                    Know More
                </button>
            </Link>
        </div>
    )
}
