import React from 'react'
import ServicesCard from '../Cards/ServicesCard'
import service1 from '../../assets/services/service-1.svg'
import { Icon } from '@iconify/react';

export default function FBARProcessing() {
  return (
    <div>
      <ServicesCard imgSrc={service1} heading={"FBAR/FATCA Processing"}>
        <div className='flex flex-col gap-[1.5rem] text-[1rem] sm:text-[1.1rem] lg:text-[1.3rem] lg:leading-8 font-[500] mt-[1rem]'>
          <p>
            You will be asked to report your financial transaction details to the United States Department of Treasury on a yearly basis if you possess the signature authority on any international business or savings bank account, mutual funds or unit trusts. As per the Back Secrecy Act, every individual residing in the US have to report the details of their foreign bank account they have in their possession. Alternatively, the US residents can opt for filing form-114 or FinCEN in case the total amount of the foreign bank accounts is $10K or more.
          </p>
          <p>
            The individuals resigning in the US have to remember that they are required to submit Form-114 to the United States Department of Treasury. If you fail to submit the Form-114, you would be penalized heavily, In worst cases, you will be prosecuted for criminal charges. In case you fall under any of the below-mentioned conditions, you are required to file FATCA akin with Form-114.
          </p>
          <ul className='flex flex-col gap-[1rem]'>
            <li className='flex items-start gap-[0.5rem] xs:gap-[1rem]'>
              <Icon icon="mdi:star-four-points" className='text-primary mt-[0.3rem] shrink-0' />
              <span>
                In case you are residing in the USA and filing tax as an individual. You have foreign assets valuing more than $50K. If the value of your foreign assets is more than $75K at any point of time of the financial year, you are required to file FATCA.
              </span>
            </li>
            <li className='flex items-start gap-[0.5rem] xs:gap-[1rem]'>
              <Icon icon="mdi:star-four-points" className='text-primary mt-[0.3rem] shrink-0' />
              <span>
                If you are US expat living in abroad and filing taxes as an individual. You have foreign assets valuing more than $200K. If the value of your foreign assets is more than $300K at any point of time of the financial year, you are required to file FATCA.
              </span>
            </li>
            <li className='flex items-start gap-[0.5rem] xs:gap-[1rem]'>
              <Icon icon="mdi:star-four-points" className='text-primary mt-[0.3rem] shrink-0' />
              <span>
                If you are residing in the USA and filing tax as a joint return with your spouse. You have foreign assets valuing more than $100K. If the value of your foreign assets is more than $150K at any point of time of the financial year, you are required to file FATCA.
              </span>
            </li>
          </ul>
        </div>
      </ServicesCard>
    </div>
  )
}
