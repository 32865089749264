import React from 'react'
import ServicesCard from '../Cards/ServicesCard'
import service1 from '../../assets/services/service-1.svg'

export default function LtdLiabilityCompany() {
  return (
    <div>
      <ServicesCard imgSrc={service1} heading={"Limited Liability Company"}>
        <div className='flex flex-col gap-[1.5rem] text-[1rem] sm:text-[1.1rem] lg:text-[1.3rem] lg:leading-8 font-[500] mt-[1rem]'>
          <p>
            Limited Liability Company (LLC) is a business structure that derives properties from both the sole ownership and corporation model of business. Usually, the LLC qualifies to pass through the taxation feature of sole ownership or partnership model of business. Contrary, it also limits the liability of the owners that resembles a corporation
          </p>
          <p>
            In most cases, the LLC will not be considered as a separate entity. Hence, the company does not count on its loses or pay taxes. The owners of the company will take care of this aspect as they need to account for the profits amassed by the company. They are required to report the profit or losses of the company on their personal tax returns. Just like the corporation, the LLC are also secured from disclosing personal liabilities.
          </p>
        </div>
      </ServicesCard>
    </div>
  )
}
