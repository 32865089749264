import React, { useState } from 'react'
import ERTlogosvg from '../..//assets/home/ERT-Logosvg.svg'
import { Link, NavLink } from 'react-router-dom'
import { Icon } from '@iconify/react';
import { useDispatch, useSelector } from 'react-redux';
import { toggleNavbar } from '../../store/layoutSlice';
import './style.css'
import ERTNewLogo1 from "../../assets/home/ERT-new-logo1.svg"


export default function Header() {
  const [showDropdown, setShowDropDown] = useState(true);
  const { sideMenuOpen } = useSelector((store) => store.layout);
  const dispatch = useDispatch();

  const showDropdownHandler = () => {
    setShowDropDown(false);
    setTimeout(() => {
      setShowDropDown(true);
    }, 100)
  }

  return (
    <div className='relative'>
      <div className='h-[5rem] lg:h-[6rem] flex items-center lg:max-xl:justify-center justify-between 
       font-[500] px-[1rem] xl:px-[1rem] shadow-md fixed w-full bg-white z-40'>
        <div className=''>
          <Link to="/">
            <img className='w-[40%] lg:w-[40%] xl:w-[50%]'
              src={ERTNewLogo1} alt="ERT-Logo" />
          </Link>
        </div>
        <div className='flex gap-[1.5rem] xl:gap-[2rem] items-center max-lg:hidden '>
          <ul className='flex gap-[1rem] xl:gap-[2rem] text-[1rem] xl:text-[1.1rem] custom-menu'>
            <li>
              <NavLink to="/"
                className={({ isActive }) =>
                  `${isActive
                    ? "text-primary font-bold border-b-[0.15rem] border-primary"
                    : ""
                  }`
                }
              >
                {/* <span className="bg-primary h-2 w-2 rounded-full mr-2 inline-block"></span> */}
                Home
              </NavLink>
            </li>
            <li className='whitespace-nowrap'>
              <NavLink to="/about"
                className={({ isActive }) =>
                  `${isActive
                    ? "text-primary font-bold border-b-[0.15rem] border-primary"
                    : ""
                  }`
                }
              >
                About us
              </NavLink>
            </li>
            {/* Services Dropdown */}
            <li className='whitespace-nowrap relative'>
              <li className='flex items-center cursor-pointer' disabled={true}>
                <NavLink to={"services"}
                  className={({ isActive }) =>
                    `flex items-center cursor-pointer pointer-events-none ${isActive
                      ? "text-primary font-bold border-b-[0.15rem] border-primary"
                      : ""
                    }`
                  }
                >
                  <span>Services</span> <Icon icon="icon-park-outline:down" className={`text-[1.5rem]`} />
                </NavLink>
              </li>
              {showDropdown && (
                <ul className={`absolute bg-[white] shadow-xl custom-submenu z-20`}>
                  <li className='whitespace-nowrap border-b border-b-black px-[2rem] py-[1rem] hover:bg-white/[0.8]'>
                    <li className='cursor-pointer'>
                      US individual
                    </li>
                    <ul className='custom-submenu2 text-[1rem] absolute left-[100%] top-0 bg-[#F3F3F3] shadow-xl'>
                      <NavLink to={"/services/us-individual/federal-tax-filing"}
                        onClick={showDropdownHandler}
                        className={({ isActive }) => `${isActive ? "text-primary" : ""}`}
                      >
                        <li className={`px-[2rem] py-[1.08rem] border-b border-b-black hover:bg-white`}>
                          Federal and State Tax Filing
                        </li>
                      </NavLink>
                      <NavLink to={"/services/us-individual/fbar-processing"}
                        onClick={showDropdownHandler}
                        className={({ isActive }) => `${isActive ? "text-primary" : ""}`}
                      >
                        <li className='px-[2rem] py-[1rem] border-b border-b-black hover:bg-white'>
                          FBAR/FATCA Processing
                        </li>
                      </NavLink>
                      <NavLink to={"/services/us-individual/itin-processing"}
                        onClick={showDropdownHandler}
                        className={({ isActive }) => `${isActive ? "text-primary" : ""}`}
                      >
                        <li className='px-[2rem] py-[1rem] border-b border-b-black hover:bg-white'>
                          ITIN Processing
                        </li>
                      </NavLink>
                      <NavLink to={"/services/us-individual/representation-services"}
                        onClick={showDropdownHandler}
                        className={({ isActive }) => `${isActive ? "text-primary" : ""}`}
                      >
                        <li className='px-[2rem] py-[1rem] border-b border-b-black hover:bg-white'>
                          Representation Services
                        </li>
                      </NavLink>
                      <NavLink to={"/services/us-individual/amendment-filing"}
                        onClick={showDropdownHandler}
                        className={({ isActive }) => `${isActive ? "text-primary" : ""}`}
                      >
                        <li className='px-[2rem] py-[1rem] border-b border-b-black hover:bg-white'>
                          Amendment Filing
                        </li>
                      </NavLink>
                      <NavLink to={"/services/us-individual/indian-tax-filing"}
                        onClick={showDropdownHandler}
                        className={({ isActive }) => `${isActive ? "text-primary" : ""}`}
                      >
                        <li className='px-[2rem] py-[1rem] hover:bg-white'>Indian Tax Filing</li>
                      </NavLink>
                    </ul>
                  </li>
                  <li className='whitespace-nowrap border-b border-b-white px-[2rem] py-[1rem]'>
                    <li className='cursor-pointer'>
                      US Business
                    </li>
                    <ul className='custom-submenu2 text-[1rem] absolute left-[100%] top-0 bg-[#F3F3F3] shadow-xl'>
                      <NavLink to={"/services/us-business/entity-formation"}
                        onClick={showDropdownHandler}
                        className={({ isActive }) => `${isActive ? "text-primary" : ""}`}
                      >
                        <li className='px-[2rem] py-[1.08rem] border-b border-b-black hover:bg-white'>
                          Entity Formation
                        </li>
                      </NavLink>
                      <NavLink to={"/services/us-business/corporate-tax-filing"}
                        onClick={showDropdownHandler}
                        className={({ isActive }) => `${isActive ? "text-primary" : ""}`}
                      >
                        <li className='px-[2rem] py-[1rem] border-b border-b-black hover:bg-white'>
                          Corporate Tax Filing
                        </li>
                      </NavLink>
                      <NavLink to={"/services/us-business/ltd-liability-company"}
                        onClick={showDropdownHandler}
                        className={({ isActive }) => `${isActive ? "text-primary" : ""}`}
                      >
                        <li className='px-[2rem] py-[1rem] hover:bg-white'>Limited Liability Company</li>
                      </NavLink>
                    </ul>
                  </li>
                </ul>
              )}
            </li>
            <li className='whitespace-nowrap'>
              <NavLink to="/refund-estimate"
                className={({ isActive }) =>
                  `${isActive
                    ? "text-primary font-bold border-b-[0.15rem] border-primary"
                    : ""
                  }`
                }>
                Refund Estimate
              </NavLink>
            </li>
            <li className='whitespace-nowrap'>
              <NavLink to="/refund-status"
                className={({ isActive }) =>
                  `${isActive
                    ? "text-primary font-bold border-b-[0.15rem] border-primary"
                    : ""
                  }`
                }>
                Refund Status
              </NavLink>
            </li>
            <li className='whitespace-nowrap'>
              <NavLink to="/contact"
                className={({ isActive }) =>
                  `${isActive
                    ? "text-primary font-bold border-b-[0.15rem] border-primary"
                    : ""
                  }`
                }>
                Contact us
              </NavLink>
            </li>
            <li className='whitespace-nowrap'>
              <NavLink to="/refer-earn"
                className={({ isActive }) =>
                  `${isActive
                    ? "text-primary font-bold border-b-[0.15rem] border-primary"
                    : ""
                  }`
                }>
                Refer & Earn $10
              </NavLink>
            </li>

          </ul>
          <div className=' text-[1rem] flex items-center justify-center gap-[1rem] text-white whitespace-nowrap'>
            <a href="https://www.client.eazyrefundtax.com/client_login/index">
              <button
                className='bg-primary py-[0.25rem] xl:py-[0.3rem] text-[0.9rem] xl:text-[1rem] w-[4rem] xl:w-[5rem] rounded-[6px]'>
                Log In
              </button>
            </a>
            <a href="https://www.client.eazyrefundtax.com/web/registrationform">
              <button className='bg-primary py-[0.25rem] xl:py-[0.3rem] text-[0.9rem] xl:text-[1rem] w-[4rem] xl:w-[5rem]  rounded-[6px]'>
                Sign Up
              </button>
            </a>
          </div>
        </div>
        {!sideMenuOpen ? (
          <div className=" lg:hidden text-[4rem] cursor-pointer text-primary"
            onClick={() => dispatch(toggleNavbar())}>
            <Icon icon="material-symbols:menu" />
          </div>
        ) : (
          <div className=" lg:hidden text-[3.5rem] cursor-pointer"
            onClick={() => dispatch(toggleNavbar())}>
            <Icon icon="material-symbols:close" />
          </div>
        )}
      </div>
    </div>
  )
}
