import React from 'react'
import ServicesCard from '../Cards/ServicesCard'
import service1 from '../../assets/services/service-1.svg'

export default function CorporateTaxFiling() {
  return (
    <ServicesCard imgSrc={service1} heading={"Corporate Tax Filing"}>
      <div className='flex flex-col gap-[1.5rem] text-[1rem] sm:text-[1.1rem] lg:text-[1.3rem] lg:leading-8 font-[500]'>
        <p>
          Our corporate tax filing services include but not limited to
        </p>
        <h1 className='font-bold text-[1.3rem] xs:max-lg:text-[1.6rem] lg:text-[2rem] text-primary sm:mt-[1rem]'>
          For Sole Ownership
        </h1>
        <p>
          The name sole ownership itself says that these businesses are owned by single persons and the owners of these companies are required to file form 1040 with pricing-C. The owner of the sole ownership companies is responsible for managing the full business operations. Unlike corporations or LLCs, the sole ownership business includes less paperwork and faces a limited amount of restrictions along with regulations. The process of setting up the sole ownership business model is Eazy and involves minimal cost. The sole owner of the company has to get the local business license along with the business registration.
        </p>
        <h1 className='font-bold text-[1.3rem] xs:max-lg:text-[1.6rem] lg:text-[2rem] text-primary sm:mt-[1rem]'>
          For Partnership Businesses
        </h1>
        <p>
          Partnership businesses refer to businesses that are managed by two or more persons. The owners of the partnership businesses are required to file Form-1065. As the owner of the business, you are required to provide pricing K-1 to your partners. Always remember that the partnership modelled companies are not required to pay taxes on the profits rather they have to declare the operating profits and losses to the IRS in Form-1065. The owner of the business is also required to provide form K1 to the partners to state the profits and losses to each partner involved in the business setup. These forms help the partners of the company to know the amount of money they owe to the IRS.
        </p>
        <h1 className='font-bold text-[1.3rem] xs:max-lg:text-[1.6rem] lg:text-[2rem] text-primary sm:mt-[1rem]'>
          For Corporations
        </h1>
        <p>
          The corporations offer limited liability protection to the directions and stakeholders of the company. The corporate modelled business are required to file Form-1120. When it comes to the purpose of federal income tax filings, the corporations are different taxpayers. As a corporation, the owners of the business are required to file their own federal income tax return.
        </p>
        <h1 className='font-bold text-[1.3rem] xs:max-lg:text-[1.6rem] lg:text-[2rem] text-primary sm:mt-[1rem]'>
          For S Corporations
        </h1>
        <p>
          The S Corporations are required to file form-1120S. Usually, the S Corporations are the corporations that are elected to pass profits, income and losses to the stakeholders. In the personal tax returns, the owners of these companies will report their income flow. So, S Corporations can avoid double taxation.
        </p>
      </div>
    </ServicesCard>
  )
}
