import React from 'react'
import ServicesCard from '../Cards/ServicesCard'
import service1 from '../../assets/services/service-1.svg'

export default function IndianTaxFiling() {
  return (
    <div>
      <ServicesCard imgSrc={service1} heading={"Indian Tax Filing"}>
        <div className='flex flex-col gap-[1.5rem] text-[1rem] sm:text-[1.1rem] lg:text-[1.3rem] lg:leading-8 font-[500] mt-[1rem]'>
          <p>
            Eazy Refund Tax has incorporated a dedicated processing center solely for India. It helps our Indian clients with business interests in the USA to file tax returns. We also file returns on behalf of you. According to the Income Tax Act of 1962, everyone with the net income that exceeds basic exempt has to file income tax returns along with the proof of their income on or before the due date. Furthermore, the salary of the NRIs is taxable in the US if they are appointed in India. Contrary, the NRI who has been employed in abroad but receiving salary in India has to file the tax.
          </p>
        </div>
      </ServicesCard>
    </div>
  )
}
