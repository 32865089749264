import React, { useState, useEffect } from 'react'
import refund from '../assets/refund-estimate.svg'
import leafImg from '../assets/home/leafImg.svg'
import { Icon } from "@iconify/react";

export default function RefundEstimate() {

  const [selectImg, setSelectImg] = useState();

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    })
  }, [])

  return (
    <div className='px-[1rem] sm:px-[3rem] xl:px-[5rem]'>
      <div className='flex md:flex-row flex-col items-center justify-between w-full max-md:gap-[1rem] 
      my-[1.5rem] sm:my-[2rem] md:my-0'>
        <div className='w-full md:w-[40%]'>
          <h1 className='text-primary text-[2.2rem] lg:text-[2.5rem] max-md:text-center font-bold'>
            Refund Estimate
          </h1>
          <p className='text-secondary text-[1rem] sm:text-[1.1rem] lg:text-[1.3rem] max-md:text-center 
        mt-[1rem] lg:leading-9'>
            Online tax calculators or tax preparation software can help you enter your financial information and provide a preliminary estimate of your tax refund.
          </p>
        </div>
        <div className='w-[95%] sm:w-[80%] md:w-[50%] xl:w-[45%]'>
          <img src={refund} alt="" />
        </div>
      </div>
      <div className='my-[3rem]'>
        <div className='flex items-center whitespace-nowrap'>
          <h1 className='font-bold text-[1.3rem] sm:text-[2rem] lg:text-[2.5rem] text-primary'>
            Refund Estimate
          </h1>
          <img src={leafImg} alt="leaf" className='max-xxs:hidden' />
        </div>
        <form className='flex flex-col gap-[1rem]'>
          <div className='flex flex-col gap-[0.4rem]'>
            <label htmlFor="name" className='text-[1rem] sm:text-[1.2rem] font-[500]'>Name</label>
            <input
              className='py-[0.5rem] sm:py-[0.6rem] px-[1rem] text-[1rem] sm:text-[1.1rem] border border-[#767D8666] rounded-[4px] outline-none'
              type="text"
              placeholder='Enter Your Name' />
            {/* <p>Error Message</p> */}
          </div>
          <div className='flex flex-col gap-[0.5rem]'>
            <label htmlFor="email" className='text-[1rem] sm:text-[1.2rem] font-[500]'>Email</label>
            <input
              className='py-[0.5rem] sm:py-[0.6rem] px-[1rem] text-[1rem] sm:text-[1.1rem] border border-[#767D8666] rounded-[4px] outline-none'
              type="email"
              placeholder='Enter Your Email' />
            {/* <p>Error Message</p> */}
          </div>
          <div className='flex flex-col gap-[0.5rem]'>
            <label htmlFor="email" className='text-[1rem] sm:text-[1.2rem] font-[500]'>
              Upload File (W2/Paystub)
            </label>
            <div className="flex items-center px-[0.5rem] xs:px-[1rem] py-[0.5rem] xs:py-[1rem] border border-[#767D8666] rounded-[4px]">
              <div className="flex items-center gap-[1rem] xs:gap-[2rem]">
                <button
                  type="button"
                  className="text-[0.9rem] xs:text-[1.1rem] font-semibold text-black bg-[#D1D4D7] py-[0.3rem] xs:py-[0.5rem] px-[0.5rem] xs:px-[2rem] rounded-lg whitespace-nowrap"
                  onClick={() => document.querySelector(".input-field").click()}
                >
                  Choose File
                </button>
                {selectImg?.name ? (
                  <>
                    <h3 className="text-[0.8rem] xs:text-[1rem] text-black font-[500]">
                      {selectImg?.name.length > 30
                        ? selectImg.name.substring(0, 25) + "..."
                        : selectImg.name}
                    </h3>
                    <Icon
                      onClick={() => setSelectImg(null)}
                      icon="akar-icons:cross"
                      className="text-[1.2rem] cursor-pointer hover:bg-[#3D4A5C]/[0.3]"
                    />
                  </>
                ) : (
                  <h3 className="text-[1rem] text-[#3D4A5C]">No file selected</h3>
                )}
              </div>
              <input
                type="file"
                className="input-field"
                value=""
                hidden
                onChange={({ target: { files } }) => {
                  if (files[0]) {
                    setSelectImg(files[0]);
                  }
                }}
              />
            </div>
          </div>
          <div className='flex flex-col gap-[0.5rem]'>
            <label htmlFor="email" className='text-[1rem] sm:text-[1.2rem] font-[500]'>Message</label>
            <textarea
              className='py-[0.5rem] sm:py-[0.6rem] px-[1rem] text-[1rem] sm:text-[1.1rem] border border-[#767D8666] rounded-[4px] outline-none'
              type="email"
              placeholder='Enter Your Message here...'
              rows={5}
            />
          </div>
          <div className='flex justify-end mt-[1.5rem]'>
            <button className='py-[0.5rem] sm:py-[0.6rem] px-[2rem] sm:px-[3rem] bg-primary text-white rounded-[6px] font-[500]'
              type="submit"
            >
              Submit
            </button>
          </div>
        </form>
      </div>
    </div>
  )
}
