import React, { useEffect } from 'react'
import AboutMain from './AboutMain'
import TaxServices from './TaxServices'
import OurTeam from './OurTeam'
import GetInTouch from '../Home/GetInTouch'

export default function AboutContainer() {

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    })
  }, [])

  return (
    <div className=''>
      <AboutMain />
      <TaxServices />
      {/* <OurTeam /> */}
      <GetInTouch />
    </div>
  )
}
