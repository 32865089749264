import React from 'react'
import Header from '../components/navbar/Header'
import Footer from '../components/Footer'
import { Outlet } from 'react-router-dom'
import SideMenuPanel from '../components/navbar/SideMenuPanel'
import { useSelector } from 'react-redux'

export default function AppLayout() {

    const { sideMenuOpen } = useSelector((store) => store.layout);
    console.log(sideMenuOpen);

    return (
        <div>
            <Header />
            {sideMenuOpen && <SideMenuPanel />}
            <div className='pt-[5rem] lg:pt-[6rem]'>
                <Outlet />
            </div>
            <Footer />
        </div>
    )
}
