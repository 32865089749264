import React from 'react'
import ServicesCard from '../Cards/ServicesCard'
import service1 from '../../assets/services/service-1.svg'
import { Icon } from '@iconify/react';

export default function FederalTaxFiling() {
  return (
    <div>
      <ServicesCard imgSrc={service1} heading={"Federal and State Tax Filing"}>
        <div className='flex flex-col gap-[1.5rem] text-[1rem] sm:text-[1.1rem] lg:text-[1.3rem] lg:leading-8 font-[500] mt-[1rem]'>
          <p>
            We, at Eazy Refund Tax, has a dedicated E-Return Originator that is legally registered with the latest IRS Rules and Circular 230. We ensure that our e-return platform compiles all the legal norms and regulations. We hold highly experienced and registered Tax return prepares, CAs, EAs and CPAs who hold Preparer Tax Identification Number abbreviated as PTIN.
          </p>
          <p>
            We always approach your requirements on a proactive vs reactive basis. All our tax filers strive to know about the latest updates of tax regulations. This has enabled us to help you minimize your tax liabilities. Our tax filing services include but not limited to:
          </p>
          <ul className='flex flex-col gap-[1rem]'>
            <li className='flex items-start gap-[0.5rem] xs:gap-[1rem]'>
              <Icon icon="mdi:star-four-points" className='text-primary mt-[0.3rem] shrink-0'/>
              <span>
                Assistance with W4
              </span>
            </li>
            <li className='flex items-start gap-[0.5rem] xs:gap-[1rem]'>
              <Icon icon="mdi:star-four-points" className='text-primary mt-[0.3rem] shrink-0' />
              <span>
                Help with W7 form or ITIN application
              </span>
            </li>
            <li className='flex items-start gap-[0.5rem] xs:gap-[1rem]'>
              <Icon icon="mdi:star-four-points" className='text-primary mt-[0.3rem] shrink-0' />
              <span>
                Certification of Passport
              </span>
            </li>
            <li className='flex items-start gap-[0.5rem] xs:gap-[1rem]'>
              <Icon icon="mdi:star-four-points" className='text-primary mt-[0.3rem] shrink-0' />
              <span>
                E-Filing or Paper Filing
              </span>
            </li>
            <li className='flex items-start gap-[0.5rem] xs:gap-[1rem]'>
              <Icon icon="mdi:star-four-points" className='text-primary mt-[0.3rem] shrink-0' />
              <span>
                Preparation of State and Federal Tax Returns
              </span>
            </li>
            <li className='flex items-start gap-[0.5rem] xs:gap-[1rem]'>
              <Icon icon="mdi:star-four-points" className='text-primary mt-[0.3rem] shrink-0' />
              <span>
                Changing the Tax Returns
              </span>
            </li>
            <li className='flex items-start gap-[0.5rem] xs:gap-[1rem]'>
              <Icon icon="mdi:star-four-points" className='text-primary mt-[0.3rem] shrink-0' />
              <span>
                Extending the Tax Returns
              </span>
            </li>
            <li className='flex items-start gap-[0.5rem] xs:gap-[1rem]'>
              <Icon icon="mdi:star-four-points" className='text-primary mt-[0.3rem] shrink-0' />
              <span>
                Assistance with Individual Retirement Arrangement
              </span>
            </li>
            <li className='flex items-start gap-[0.5rem] xs:gap-[1rem]'>
              <Icon icon="mdi:star-four-points" className='text-primary mt-[0.3rem] shrink-0' />
              <span>
                Filing account reporting with foreign banks
              </span>
            </li>
            <li className='flex items-start gap-[0.5rem] xs:gap-[1rem]'>
              <Icon icon="mdi:star-four-points" className='text-primary mt-[0.3rem] shrink-0' />
              <span>
                Filing FATCA
              </span>
            </li>
          </ul>
        </div>
      </ServicesCard>
    </div>
  )
}
