import React from 'react'
import leafImg from '../../assets/home/leafImg.svg'
import handshake from '../../assets/about/handshake.svg'
import worldCare from '../../assets/about/world-care.svg'
import handStar from '../../assets/about/hand-star.svg'
import cloudComputing from '../../assets/about/cloud-computing.svg'
import { Link } from 'react-router-dom'

export default function TaxServices() {

    return (
        <div className='px-[1rem] sm:px-[3rem] lg:px-[5rem] my-[2rem]'>
            <div className='flex items-center'>
                <h1 className='font-bold text-[1.2rem] sm:text-[1.8rem] lg:text-[2.2rem] text-primary'>
                    Eazy Refund Tax Aims to Provide Premium Taxation Services
                </h1>
                <img src={leafImg} alt="leaf" className='max-xl:hidden' />
            </div>
            <p className='my-[1rem] w-full lg:w-[60%] text-secondary text-[1rem] sm:text-[1.1rem] lg:text-[1.3rem] mb-[2rem] lg:mb-[5rem] lg:leading-9'>
                Eazy Refund Tax offers expert tax and financial services, catering to non-resident Indians, H1B/L1 visa holders, and F1 CPT/OPT students. Our experienced team ensures your financial success and security, providing 24/7 support. Choose us for professional tax services.
            </p>

            <div className='w-full flex flex-col lg:flex-row max-lg:gap-[2rem] justify-between'>
                <div className='w-full lg:w-[35%] flex flex-col gap-[1rem] lg:gap-[1.5rem]'>
                    <h1 className='text-[1.1rem] sm:text-[1.2rem] lg:text-[1.5rem] font-[500]'>
                        "Quality Tax Filing Services for Your Financial Well-being"
                    </h1>
                    <p className='text-secondary text-[1rem] sm:text-[1.1rem] lg:text-[1.3rem] lg:leading-9'>
                        We excel in delivering on our promises, offering premium tax filing services by expert CPAs and EAs to save you money and ensure your satisfaction.
                    </p>
                    <div className='mt-[1rem]'>
                        <Link to={"/contact"}>
                            <button
                                className='whitespace-nowrap text-primary font-[500] border border-primary 
                        px-[2rem] py-[0.5rem] rounded-[8px] hover:text-white hover:bg-primary'>
                                Contact us
                            </button>
                        </Link>
                    </div>
                </div>
                <div className='grid grid-cols-2 gap-x-[1rem] lg:gap-x-[4rem] gap-y-[2rem] max-lg:border-none border border-secondary/[0.3] py-[1rem] lg:py-[2rem] lg:px-[2rem] rounded-[10px] md:max-lg:place-items-center'>
                    <div className='flex flex-col items-start gap-[1rem]'>
                        <div className='bg-[#FFF2F2] p-[0.8rem] xs:p-[1rem] md:p-[1.5rem] rounded-[14px] xs:rounded-[20px]'>
                            <img src={handshake} alt="handshakeIcon" />
                        </div>
                        <h1 className='uppercase text-[0.8rem] xs:text-[1rem] md:text-[1.1rem] font-[500]'>We Remain True to Our Promises</h1>
                    </div>
                    <div className='flex flex-col items-start gap-[1rem]'>
                        <div className='bg-[#E2F3FF] p-[0.8rem] xs:p-[1rem] md:p-[1.5rem] rounded-[14px] xs:rounded-[20px]'>
                            <img src={worldCare} alt="worldCareIcon" />
                        </div>
                        <h1 className='uppercase text-[0.8rem] xs:text-[1rem] md:text-[1.1rem] font-[500]'>We Care for Your Welfare</h1>
                    </div>
                    <div className='flex flex-col items-start gap-[1rem]'>
                        <div className='bg-[#F1E8FF] p-[0.8rem] xs:p-[1rem] md:p-[1.5rem] rounded-[14px] xs:rounded-[20px]'>
                            <img src={handStar} alt="handStarIcon" />
                        </div>
                        <h1 className='uppercase text-[0.8rem] xs:text-[1rem] md:text-[1.1rem] font-[500]'>WE ENSURE YOUR SATISFACTION</h1>
                    </div>
                    <div className='flex flex-col items-start gap-[1rem]'>
                        <div className='bg-[#FFE7FB] p-[0.8rem] xs:p-[1rem] md:p-[1.5rem] rounded-[14px] xs:rounded-[20px]'>
                            <img src={cloudComputing} alt="cloudComputingIcon" />
                        </div>
                        <h1 className='uppercase text-[0.8rem] xs:text-[1rem] md:text-[1.1rem] font-[500]'>DELIVER OUTSTANDING RESULTS</h1>
                    </div>
                </div>
            </div>

        </div>
    )
}
