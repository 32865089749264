import React from 'react'
import homeAbout from '../../assets/home/homeAbout.svg'
import homeAboutPic from '../../assets/home/homeAboutPic.svg'

export default function HomeAbout() {
  return (
    <div className='flex flex-col items-center max-md:my-[2rem] md:my-[3rem] px-[1rem] sm:px-[2rem]'>
      <div>
        <img src={homeAbout} alt="homeAboutTitle" />
      </div>
      <div className='flex flex-col md:flex-row items-center justify-between'>
        <div className='w-full md:w-[40%] max-md:my-[1rem]'>
          <img src={homeAboutPic} alt="" />
        </div>
        <div className='w-full md:w-[55%]'>
          <h1 className='text-[1.2rem] sm:text-[1.5rem] lg:text-[1.9rem] xl:text-[2.1rem] font-bold max-md:text-center'>
            Accessible Approachable Accountable
          </h1>
          <p className='my-[1rem] text-secondary text-[1rem] sm:text-[1.1rem] lg:text-[1.3rem] lg:leading-9'>
            Eazy Refund Tax holds the team of highly experienced and specialized professionals who have more than a decade of experience working in the industry. All the people working at Eazy Refund Tax are efficient in business book keeping, taxation, US individual taxations and payroll services. We strive to provide highly professional taxation services in the best possible way.
          </p>
        </div>
      </div>
    </div>
  )
}
