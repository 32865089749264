import React from 'react'
import ReCAPTCHA from "react-google-recaptcha";
// import inTouchEllipse1 from "../../assets/home/inTouchEllipse1.svg";

export default function GetInTouch() {

  const SITE_KEY = "6LevqqooAAAAABBtChk1xEYRPKK8sAE_0HWFTWFn";

  return (
    <div className='flex justify-center items-center h-[750px] md:h-[600px] relative'>
      <div className='w-[95%] xs:max-xl:w-[90%] xl:w-[80%] bg-[#FFEFEE] flex flex-col md:flex-row md:justify-between justify-center max-md:gap-[2rem] items-center px-[1rem] sm:max-lg:px-[2rem] lg:px-[5rem] xl:px-[7rem] h-[750px] md:h-[600px] absolute bottom-[-2rem] z-10'
        style={{
          boxShadow: "1px 1px 2px 0px rgba(0, 0, 0, 0.1)",
        }}>
        <div className='w-[95%] md:w-[50%] flex flex-col gap-[0.5rem] max-md:text-center'>
          <h1 className='text-primary font-bold text-[1.3rem] sm:text-[2rem] lg:text-[2.5rem]'>
            Let's Get in Touch!
          </h1>
          <p className='text-secondary text-[0.9rem] sm:text-[1rem]'>
            Have a question or need assistance? Reach out to us via email,
            phone, or the contact from below. We're eager to assist you.
          </p>
          <h5 className='text-primary text-[1rem] font-bold'>Nice hearing from you!</h5>
          {/* <img src={inTouchEllipse1} alt="" className='w-[10rem]'/> */}
        </div>
        <div className='bg-white px-[0.5rem] xs:px-[2rem] py-[1.5rem] xs:py-[2rem] rounded-[6px] max-md:w-full'>
          <form className='flex flex-col gap-[1rem]'>
            <div className='flex flex-col gap-[0.4rem]'>
              <label htmlFor="name" className='font-[500]'>Name</label>
              <input
                className='w-full md:w-[20rem] py-[0.5rem] px-[1rem] border border-[#767D8666] rounded-[4px] outline-none'
                type="text"
                placeholder='Enter Your Name' />
              {/* <p>Error Message</p> */}
            </div>
            <div className='flex flex-col gap-[0.5rem]'>
              <label htmlFor="phoneNumber" className='font-[500]'>Phone Number</label>
              <input
                className='w-full md:w-[20rem] py-[0.5rem] px-[1rem] border border-[#767D8666] rounded-[4px]  outline-none'
                type="number"
                step="any"
                placeholder='Enter Your Number' />
              {/* <p>Error Message</p> */}
            </div>
            <div className='flex flex-col gap-[0.5rem]'>
              <label htmlFor="email" className='font-[500]'>Email</label>
              <input
                className='w-full md:w-[20rem] py-[0.5rem] px-[1rem] border border-[#767D8666] rounded-[4px]  outline-none'
                type="email"
                step="any"
                placeholder='Enter Your Email' />
              {/* <p>Error Message</p> */}
            </div>
            <div className='flex flex-col gap-[0.5rem]'>
              <ReCAPTCHA className='mt-[0.2rem] sm:mt-[0.5rem] overflow-auto'
                sitekey={SITE_KEY}
              // value={reCaptcha}
              // onChange={(value) => dispatch(updatePersonalDetails({
              //     reCaptcha: value,
              // }))}
              />
              {/* <p>Error Message</p> */}
            </div>
            <div className='flex justify-end mt-[1rem] xs:mt-[1.5rem]'>
              <button className='py-[0.5rem] px-[2rem] sm:px-[3rem] bg-primary text-white rounded-[6px]
              font-[500]'
                type="submit"
              >
                Submit
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  )
}
