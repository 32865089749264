import React, { useEffect } from 'react'

export default function TermsConditions() {

   useEffect(() => {
      window.scrollTo({ top: 0, behavior: 'smooth' });
   }, [])

   return (
      <div className='my-[1rem] sm:max-md:my-[2rem] md:my-[4rem] flex justify-center items-center'>
         <div className='flex flex-col gap-[1.5rem] items-start w-[100%] md:w-[90%] px-[1rem] py-[1rem] md:py-[2rem] md:px-[3rem] rounded-none md:rounded-[30px]'
            style={{
               boxShadow: "-8px -8px 16px rgba(0, 0, 0, 0.1), 8px 8px 16px rgba(0, 0, 0, 0.1)",
               ...(window.innerWidth <= 768 && {
                  boxShadow: "none",
               })
            }}
         >
            <h2 className='text-[1rem] md:text-[1.3rem] font-semibold'>
               Please read these terms of use carefully before using this site:
            </h2>
            <p className='text-[0.9rem] md:text-[1.1rem] tracking-wide sm:tracking-wider mb-[1rem]'>
               By admittance to this website, you are agreeing to be bound by this website Terms and Conditions of Use, all applicable laws and regulations, and agree that you are responsible for compliance with any applicable local laws. The materials contained in this website are protected by applicable copyright and trade mark law. EazyRefund Tax reserves the right, in its sole discretion, to modify or update these provisions at any time. Please check this page each time you visit this website for the most current information.
            </p>
            <h1 className='bg-primary text-white text-[1.2rem] md:text-[1.7rem] font-bold py-[0.3rem] md:py-[0.5rem] px-[1rem] md:px-[3rem] rounded-[20px] md:rounded-[30px]'>
               Privacy Policy
            </h1>
            <h2 className='text-[1.2rem] md:text-[1.7rem] font-bold border-b-4 border-primary'>
               General:
            </h2>
            <p className='text-[0.9rem] md:text-[1.1rem] tracking-wide sm:tracking-wider'>
               EazyRefund Tax is a full-service tax consulting firm offering tax return preparation, tax planning, tax compliance, accounting, bookkeeping, business advisory, business incorporation, and payroll to a wide variety of clients ranging from individuals to small to medium-sized businesses since 2003. We at EazyRefund Tax are sensitive to customer concerns and ensure absolute confidentiality and privacy of any information that is given to us. Customers can specify their preferred method of contact with us and we will not disclose their identity without their expressed or written consent. Under no circumstances will EazyRefund Tax, its advisors, agents, representatives and employees be liable or responsible for any damage or inconvenience caused or alleged to be caused by the use of our services. EazyRefund Tax, liability relating to any product or service shall be strictly limited to the amount of fees paid to and received by EazyRefund Tax for such product or service. EazyRefund Tax shall not be liable for special, indirect or consequential damages, including lost profits, even if it has been advised of the possibility of such damages, arising from any cause whatsoever, including negligence, EazyRefund Tax and its services are not a substitute for the advice of an attorney, or licensed CPA.
            </p>
            <h2 className='text-[1.2rem] md:text-[1.7rem] font-bold border-b-4 border-primary'>
               Cookies:
            </h2>
            <p className='text-[0.9rem] md:text-[1.1rem] tracking-wide sm:tracking-wider'>
               A cookie is a small text file that websites put on your computer to store information about you and your preferences. On our website, we use cookies to track users' progress through the site, allowing us to make improvements based on usage data. The information in a cookie does not contain any personally identifiable information you submit to our site.
               Once you close your browser, our access to the cookie terminates. You have the ability to accept or decline cookies. Most web browsers automatically accept cookies, but you can usually modify your browser settings to decline cookies if you prefer. If you choose not to accept the cookie, this will not affect your access to the majority of information available on our website. However, you will not be able to make full use of our online services.
            </p>
            <h2 className='text-[1.2rem] md:text-[1.7rem] font-bold border-b-4 border-primary'>
               Security:
            </h2>
            <p className='text-[0.9rem] md:text-[1.1rem] tracking-wide sm:tracking-wider'>
               EazyRefund Tax Process has a legal duty to protect the confidentiality of taxpayer information. We take all reasonable steps to protect any information you submit via our website, both online and offline, in accordance with relevant legislation in India.
               We take all appropriate steps to protect your personally identifiable information as you transmit your information from your computer to our site and to protect such information for loss, misuse, and unauthorized access, disclosure, alteration, or destruction. We use technologies to safeguard your data and operate strict security standards to prevent any unauthorized access to it.
               Where you use passwords, ID numbers, or other special access features on this site, you also have a responsibility to take reasonable steps to safeguard them.
            </p>
            <h2 className='text-[1.2rem] md:text-[1.7rem] font-bold border-b-4 border-primary'>
               Data protection and Integrity:
            </h2>
            <p className='text-[0.9rem] md:text-[1.1rem] tracking-wide sm:tracking-wider'>
               EazyRefund Tax has reasonable security policies and procedures in place to protect personal information from unauthorized loss, misuse, alteration, or destruction. Despite EazyRefund Tax's best efforts, however, security cannot be absolutely guaranteed against all threats. To the best of our ability, access to your personal information is limited to those who have a need to know. We also make reasonable efforts to retain personal information only for so long as the information is necessary to comply with an individual's request or until that person asks that the information be deleted.
            </p>
            <h2 className='text-[1.2rem] md:text-[1.7rem] font-bold border-b-4 border-primary'>
               Unsuitable Content:
            </h2>
            <p className='text-[0.9rem] md:text-[1.1rem] tracking-wide sm:tracking-wider'>
               When accessing the EazyRefund Tax website or using EazyRefund Tax services, you agree not to upload, download, display, perform, transmit, or otherwise distribute any content that is (i) libellous, defamatory, obscene, pornographic, abusive, or threatening; (b) advocates or encourages conduct that could constitute a criminal offence, give rise to civil liability, or otherwise violate any applicable local, state, national, or foreign law or regulation; or (c) advertises or otherwise solicits funds or is a solicitation for goods or services. EazyRefund Tax reserves the right to terminate or delete such material from its servers
            </p>
            <h2 className='text-[1.2rem] md:text-[1.7rem] font-bold border-b-4 border-primary'>
               Intellectual Property Laws:
            </h2>
            <p className='text-[0.9rem] md:text-[1.1rem] tracking-wide sm:tracking-wider'>
               EazyRefund Tax Process has a legal duty to protect the confidentiality of taxpayer information. We take all reasonable steps to protect any information you submit via our website, both online and offline, in accordance with relevant legislation in India.
               We take all appropriate steps to protect your personally identifiable information as you transmit your information from your computer to our site and to protect such information from loss, misuse, and unauthorized access, disclosure, alteration, or destruction. We use technologies to safeguard your data and operate strict security standards to prevent any unauthorized access to it.
               Where you use passwords, ID numbers, or other special access features on this site, you also have a responsibility to take reasonable steps to safeguard them.
            </p>
            <h2 className='text-[1.2rem] md:text-[1.7rem] font-bold border-b-4 border-primary'>
               Copyrights:
            </h2>
            <p className='text-[0.9rem] md:text-[1.1rem] tracking-wide sm:tracking-wider'>
               All Site content & design, text, graphics, the selection and arrangement thereof, ALL RIGHTS RESERVED to EazyRefund Tax.
            </p>
            <h2 className='text-[1.2rem] md:text-[1.7rem] font-bold border-b-4 border-primary'>
               Disclosure:
            </h2>
            <p className='text-[0.9rem] md:text-[1.1rem] tracking-wide sm:tracking-wider'>
               Except as otherwise stated, we may use information you provide via this site to improve the content, to customize the site to your preferences, to communicate information to you (if you have requested it), for internal marketing and research purposes, and for the purposes specified in this Privacy Policy.
            </p>
            <h2 className='text-[1.2rem] md:text-[1.7rem] font-bold border-b-4 border-primary'>
               Notification of changes to our Privacy Policy:
            </h2>
            <p className='text-[0.9rem] md:text-[1.1rem] tracking-wide sm:tracking-wider mb-[1rem]'>
               We will post details of any changes to our Privacy Policy on the website to help ensure you are always aware of the information we collect, how we use it, and in what circumstances, if any, we share it with other parties.
               For any further questions, you may simply call us at <b>646-374-0401</b> or send an email to <b>info@eazyrefundtax.com</b>, so that one of our Tax Experts can contact you and advise on various tax saving tools applicable to you.
            </p>
            <h1 className='inline-block bg-primary text-white text-[1.2rem] md:text-[1.7rem] font-bold py-[0.3rem] md:py-[0.5rem] px-[1rem] md:px-[3rem] rounded-[20px] md:rounded-[30px]'>
               RETURN POLICY
            </h1>
            <p className='text-[0.9rem] md:text-[1.1rem] tracking-wide sm:tracking-wider mb-[1rem]'>
               Fees once collected will not be returned except for delivery issues from EazyRefund Tax . For any queries on refund of fees, please write to <b>info@eazyrefundtax.com</b>.<br />
               <span className='inline-block my-[1rem]'>CONSENT FORM DISCLOSURE</span> <br />
               <span className='inline-block mb-[2rem]'>Dear Client -</span><br />
               Federal law requires this consent form be provided to you. Unless authorized by law, we cannot disclose, without your consent, your tax return information to third parties for purposes other than the preparation and filing of your tax return and, in certain limited circumstances, for purposes involving tax return preparation. If you consent to the disclosure of your tax return information, Federal law may not protect your tax return information from for further use or distribution.
               This consent to disclose may result in your tax return information being disclosed to a tax return preparer located outside the United States, including your personally identifiable information such as your Social Security Number (“SSN”). Both the tax return preparer in the United States that will disclose your SSN and the tax return preparer located outside the United States which will receive your SSN maintain an adequate data protection safeguard (as required by the regulations under 26 U.S.C. Section 7216) to protect privacy and prevent unauthorized access of tax return information. If you consent to the disclosure of your tax return information, Federal agencies may not be able to enforce U.S. laws that protect the privacy of your tax return information against a tax return preparer located outside of the U.S. to which the information is disclosed.
               I hereby authorize EazyRefund Tax to disclose all information including source documents that may or may not contain identifiable Social Security or ITIN number(s) to its authorized personnel, associates or affiliates for the purpose of preparation of my/our Individual/Business Income Tax Return for the relevant tax year.
            </p>
            <div className='font-semibold text-[1.2rem]'>
               <address>Siri heights, Ayyappa Society,</address>
               <address>Mega Hills, Madhapur,</address>
               <address>Hyderabad, Telangana - 500081</address>
            </div>
         </div>
      </div>
   )
}
