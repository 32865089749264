import { createBrowserRouter } from "react-router-dom";
import AppLayout from "../layouts/AppLayout";
import HomeContainer from "../components/Home/HomeContainer";
import AboutContainer from "../components/AboutUs/AboutContainer";
import RefundEstimate from "../components/RefundEstimate";
import ReferEarn from "../components/ReferEarn";
import ContactUs from "../components/ContactUs";
import RefundStatus from "../components/RefundStatus";
import USIndiviual from "../components/Services/USIndiviual";
import FederalTaxFiling from "../components/Services/FederalTaxFiling";
import FBARProcessing from "../components/Services/FBARProcessing";
import ITINProcessing from "../components/Services/ITINProcessing";
import RepresentationServices from "../components/Services/RepresentationServices";
import AmendmentFiling from "../components/Services/AmendmentFiling";
import IndianTaxFiling from "../components/Services/IndianTaxFiling";
import USBusiness from "../components/Services/USBusiness";
import EntityFormation from "../components/Services/EntityFormation";
import CorporateTaxFiling from "../components/Services/CorporateTaxFiling";
import LtdLiabilityCompany from "../components/Services/LtdLiabilityCompany";
import TermsConditions from "../components/TermsConditions";


export const router = createBrowserRouter([
    {
        path: '/',
        element: <AppLayout />,
        children: [
            {
                path: "",
                element: <HomeContainer />,
            },
            {
                path: "about",
                element: <AboutContainer />,
            },
            {
                path: "services",
                children: [
                    {
                        path: "us-individual",
                        element: <USIndiviual />,
                        children: [
                            {   
                                path: "federal-tax-filing",
                                element: <FederalTaxFiling />
                            },
                            {   
                                path: "fbar-processing",
                                element: <FBARProcessing />,
                            },
                            {   
                                path: "itin-processing",
                                element: <ITINProcessing />
                            },
                            {   
                                path: "representation-services",
                                element: <RepresentationServices />
                            },
                            {   
                                path: "amendment-filing",
                                element: <AmendmentFiling />
                            },
                            {   
                                path: "indian-tax-filing",
                                element: <IndianTaxFiling />
                            },
                        ]
                    },
                    {
                        path: "us-business",
                        element: <USBusiness />,
                        children: [
                            {
                                path: "entity-formation",
                                element: <EntityFormation />,
                            },
                            {
                                path: "corporate-tax-filing",
                                element: <CorporateTaxFiling />,
                            },
                            {
                                path: "ltd-liability-company",
                                element: <LtdLiabilityCompany />,
                            },
                        ]
                    },
                ]
            },
            {
                path: "refund-estimate",
                element: <RefundEstimate />,
            },
            {
                path: "refund-status",
                element: <RefundStatus />,
            },
            {
                path: "refer-earn",
                element: <ReferEarn />,
            },
            {
                path: "contact",
                element: <ContactUs />,
            },
            {
                path: "terms-conditions",
                element: <TermsConditions />,
            },
        ]
    }
])