import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { NavLink, useLocation } from 'react-router-dom'
import { toggleNavbar } from '../../store/layoutSlice';
import { Icon } from '@iconify/react';
import './style.css';



export default function SideMenuPanel() {
   const dispatch = useDispatch();
   const { sideMenuOpen } = useSelector((store) => store.layout);
   const [showType, setShowType] = useState(false);
   const [showType1, setShowType1] = useState(false);
   const [showType2, setShowType2] = useState(false);
   const [pathServices, setPathServices] = useState(false);

   const { pathname } = useLocation();

   useEffect(() => {
      let newPath = pathname.split('/');
      if (newPath.includes('services')) {
         setPathServices(true);
      } else {
         setPathServices(false);
      }
   }, [pathname])

   return (
      <div className={`fixed top-0 right-0 w-[90%] bg-black text-white z-50 h-screen overflow-y-auto`}>
         <div className={`lg:hidden flex flex-col justify-between font-[500]`}>
            <div className="text-[3.5rem] h-[5rem] lg:h-[6rem] flex justify-end items-center cursor-pointer pr-[1rem]"
               onClick={() => dispatch(toggleNavbar())}>
               <Icon icon="material-symbols:close" className='cursor-pointer' />
            </div>
            <div className='flex flex-col items-start gap-[1.5rem] px-[2rem] sm:px-[3rem] pb-[2rem]'>
               <ul className='flex flex-col gap-[1.5rem] xl:gap-[2rem] text-[1rem] xl:text-[1.1rem]'>
                  <li>
                     <NavLink to="/"
                        className={({ isActive }) =>
                           `${isActive
                              ? "text-[#C5090A] font-bold border-b-[0.15rem] border-[#C5090A]"
                              : ""
                           }`}
                        onClick={() => dispatch(toggleNavbar())}
                     >
                        Home
                     </NavLink>
                  </li>
                  {/* <hr/> */}
                  <li className='whitespace-nowrap'>
                     <NavLink to="/about"
                        className={({ isActive }) =>
                           `${isActive
                              ? "text-[#C5090A] font-bold border-b-[0.15rem] border-[#C5090A]"
                              : ""
                           }`}
                        onClick={() => dispatch(toggleNavbar())}
                     >
                        About us
                     </NavLink>
                  </li>
                  {/* Services Dropdown nested links */}
                  <div className='flex flex-col gap-[0.5rem]'>
                     <li className={`flex items-center gap-[0.5rem] ${pathServices && "text-[#C5090A] font-bold border-b-[0.15rem] border-[#C5090A]"}`}>
                        {/* <NavLink to="/services"
                           className={({ isActive }) =>
                              `flex items-center gap-[0.5rem] ${isActive
                                 ? "text-[#C5090A] font-bold border-b-[0.15rem] border-[#C5090A]"
                                 : ""
                              }`}
                        > */}
                        Services
                        {showType ?
                           <Icon onClick={() => setShowType((!showType))}
                              icon="basil:cross-solid" className={`text-[2rem] ${pathServices ? "text-[#C5090A]" : "text-white"} `} />
                           : <Icon onClick={() => setShowType((!showType))}
                              icon="basil:plus-solid" className={`text-[2rem] ${pathServices ? "text-[#C5090A]" : "text-white"} `} />}
                        {/* </NavLink> */}
                     </li>
                     {showType && (
                        <h2 className='flex items-center gap-[0.5rem]'>
                           US Indivual
                           {showType1 ?
                              <Icon onClick={() => setShowType1((!showType1))}
                                 icon="mingcute:up-line" className='text-white text-[1.5rem]' />
                              : <Icon onClick={() => setShowType1((!showType1))}
                                 icon="mingcute:down-line" className='text-white text-[1.5rem]' />}
                        </h2>
                     )}
                     {showType1 && (
                        <ul className='flex flex-col gap-[0.6rem]'>
                           <li>
                              <NavLink to={"/services/us-individual/federal-tax-filing"}
                                 className={({ isActive }) => `${isActive ? "text-primary" : ""}`}
                                 onClick={() => dispatch(toggleNavbar())}
                              >
                                 Federal and State Tax Filing
                              </NavLink>
                           </li>
                           <li>
                              <NavLink to={"/services/us-individual/fbar-processing"}
                                 className={({ isActive }) => `${isActive ? "text-primary" : ""}`}
                        onClick={() => dispatch(toggleNavbar())}
                     >
                        FBAR/FATCA Processing
                     </NavLink>
                  </li>
                  <li>
                     <NavLink to={"/services/us-individual/itin-processing"}
                        className={({ isActive }) => `${isActive ? "text-primary" : ""}`}
                        onClick={() => dispatch(toggleNavbar())}
                     >
                        ITIN Processing
                     </NavLink>
                  </li>
                  <li>
                     <NavLink to={"/services/us-individual/representation-services"}
                        className={({ isActive }) => `${isActive ? "text-primary" : ""}`}
                        onClick={() => dispatch(toggleNavbar())}
                     >
                        Representation Services
                     </NavLink>
                  </li>
                  <li>
                     <NavLink to={"/services/us-individual/amendment-filing"}
                        className={({ isActive }) => `${isActive ? "text-primary" : ""}`}
                        onClick={() => dispatch(toggleNavbar())}
                     >
                        Amendment Filing
                     </NavLink>
                  </li>
                  <li>
                     <NavLink to={"/services/us-individual/indian-tax-filing"}
                        className={({ isActive }) => `${isActive ? "text-primary" : ""}`}
                        onClick={() => dispatch(toggleNavbar())}
                     >
                        Indian Tax Filing
                     </NavLink>
                  </li>
               </ul>
                     )}
               {showType && (
                  <h2 className='flex items-center gap-[0.5rem]'>
                     US Business
                     {showType2 ?
                        <Icon onClick={() => setShowType2((!showType2))}
                           icon="mingcute:up-line" className='text-white text-[1.5rem]' />
                        : <Icon onClick={() => setShowType2((!showType2))}
                           icon="mingcute:down-line" className='text-white text-[1.5rem]' />}
                  </h2>
               )}
               {showType2 && (
                  <ul className='flex flex-col gap-[0.6rem]'>
                     <li>
                        <NavLink to={"/services/us-business/entity-formation"}
                           className={({ isActive }) => `${isActive ? "text-primary" : ""}`}
                           onClick={() => dispatch(toggleNavbar())}
                        >
                           Entity Formation
                        </NavLink>
                     </li>
                     <li>
                        <NavLink to={"/services/us-business/corporate-tax-filing"}
                           className={({ isActive }) => `${isActive ? "text-primary" : ""}`}
                           onClick={() => dispatch(toggleNavbar())}
                        >
                           Corporate Tax Filing
                        </NavLink>
                     </li>
                     <li>
                        <NavLink to={"/services/us-business/ltd-liability-company"}
                           className={({ isActive }) => `${isActive ? "text-primary" : ""}`}
                           onClick={() => dispatch(toggleNavbar())}
                        >
                           Limited Liability Company
                        </NavLink>
                     </li>
                  </ul>
               )}
            </div>
            <li className='whitespace-nowrap'>
               <NavLink to="/refund-estimate"
                  className={({ isActive }) =>
                     `${isActive
                        ? "text-[#C5090A] font-bold border-b-[0.15rem] border-[#C5090A]"
                        : ""
                     }`}
                  onClick={() => dispatch(toggleNavbar())}
               >
                  Refund Estimate
               </NavLink>
            </li>
            <li className='whitespace-nowrap'>
               <NavLink to="refund-status"
                  className={({ isActive }) =>
                     `${isActive
                        ? "text-[#C5090A] font-bold border-b-[0.15rem] border-[#C5090A]"
                        : ""
                     }`}
                  onClick={() => dispatch(toggleNavbar())}
               >
                  Refund Status
               </NavLink>
            </li>
            <li className='whitespace-nowrap'>
               <NavLink to="/contact"
                  className={({ isActive }) =>
                     `${isActive
                        ? "text-[#C5090A] font-bold border-b-[0.15rem] border-[#C5090A]"
                        : ""
                     }`}
                  onClick={() => dispatch(toggleNavbar())}
               >
                  Contact us
               </NavLink>
            </li>
            <li className='whitespace-nowrap'>
               <NavLink to="/refer-earn"
                  className={({ isActive }) =>
                     `${isActive
                        ? "text-[#C5090A] font-bold border-b-[0.15rem] border-[#C5090A]"
                        : ""
                     }`}
                  onClick={() => dispatch(toggleNavbar())}
               >
                  Refer and Earn $10
               </NavLink>
            </li>

         </ul>
         <div className='w-full text-[1rem] flex flex-col justify-center gap-[1.5rem] text-white whitespace-nowrap'>
            <a href="https://www.client.eazyrefundtax.com/client_login/index">
               <button className='bg-[#C5090A] py-[0.25rem] xl:py-[0.3rem] text-[0.9rem] xl:text-[1rem] w-[50%] xl:w-[5rem] rounded-[6px]'
                  onClick={() => dispatch(toggleNavbar())}
               >
                  Log In
               </button>
            </a>
            <a href="https://www.client.eazyrefundtax.com/web/registrationform">
               <button className='bg-[#C5090A] py-[0.25rem] xl:py-[0.3rem] text-[0.9rem] xl:text-[1rem] w-[50%] xl:w-[5rem] rounded-[6px]'
                  onClick={() => dispatch(toggleNavbar())}
               >
                  Sign Up
               </button>
            </a>
         </div>
      </div>
         </div >
      </div >
   )
}
