import React from 'react'
import leafImg from "../../assets/home/leafImg.svg"
import icon1 from "../../assets/home/taxBenefitsIcon1.svg"
import icon2 from "../../assets/home/taxBenefitsIcon2.svg"
import icon3 from "../../assets/home/taxBenefitsIcon3.svg"
import icon4 from "../../assets/home/taxBenefitsIcon4.svg"
import icon5 from "../../assets/home/taxBenefitsIcon5.svg"
import icon6 from "../../assets/home/taxBenefitsIcon6.svg"
import icon7 from "../../assets/home/taxBenefitsIcon7.svg"
import icon8 from "../../assets/home/taxBenefitsIcon8.svg"
// import ellipse1 from "../../assets/home/ellipse1.svg"
import TaxBenefitsCard from '../Cards/TaxBenefitsCard'

const TAX_BENEFITS_CARD_DATA = [
  {
    img: icon1,
    description: "High-quality Tax Filing services at competitive prices",
  },
  {
    img: icon2,
    description: "Provides assistance with W4 and W7 forms",
  },
  {
    img: icon3,
    description: "Assures error-free tax return filing",
  },
  {
    img: icon4,
    description: "Available 24/7 to provide assistance",
  },
  {
    img: icon5,
    description: "Offers free tax return estimates within 24 hours",
  },
  {
    img: icon6,
    description: "Simplifies the tax return filing process",
  },
  {
    img: icon7,
    description: "Confidential financial and tax filing data is secured",
  },
  {
    img: icon8,
    description: "Reviews previous 3 years tax return details",
  },
]

export default function TaxBenefits() {
  return (
    <div className='relative w-full h-full'>
      <div className='bg-white my-[2rem] px-[0.3rem] xs:max-sm:px-[2rem] md:max-lg:px-[0rem] lg:px-[5rem] w-full h-full'
        // style={{
        //   backgroundImage: `url(${ellipse1})`,
        //   backgroundRepeat: 'no-repeat',
        //   position: 'absolute',
        //   bottom: 0,
        //   left: 0,
        //   objectFit: 'cover',
        // }}
        >
        <div className='flex items-center justify-center whitespace-nowrap'>
          <h1 className='font-bold text-[1.3rem] sm:text-[2rem] lg:text-[2.5rem]'>
            Free World-Class Tax Benefits
          </h1>
          <img src={leafImg} alt="leaf" className='max-xxs:hidden' />
        </div>
        <div className='grid grid-cols-2 md:grid-cols-3 xl:grid-cols-4 place-items-center gap-y-[1rem] sm:gap-y-[2rem] my-[2rem]'>
          {
            TAX_BENEFITS_CARD_DATA.map((card, i) => {
              return (
                <TaxBenefitsCard key={i} img={card.img} description={card.description} />
              )
            })
          }
        </div>
      </div>
    </div>
  )
}
