import React from 'react'
import main1 from '../../assets/about/main1.svg'
import main2 from '../../assets/about/main2.svg'
import main3 from '../../assets/about/main3.svg'
import main4 from '../../assets/about/main4.svg'

export default function AboutMain() {
  return (
    <div className='flex flex-col items-center gap-[2rem] px-[1rem] sm:px-[3rem] lg:px-[2rem] my-[1rem] lg:my-[2rem]'>
      <div className='flex flex-col lg:flex-row justify-center gap-[2rem]'>
        <div className='flex gap-[1rem] xs:gap-[2rem] justify-center'>
          <div className='flex items-end'>
            <img src={main1} alt="mainPicture1" className='' />
          </div>
          <div className='flex items-end'>
            <img src={main2} alt="mainPicture1" />
          </div>
        </div>
        <div className='w-full lg:w-[50%] xl:w-[40%] flex flex-col justify-center max-lg:text-center'>
          <h1 className='text-primary text-[2.5rem] font-bold'>
            About us
          </h1>
          <h1 className='text-[1.25rem] sm:text-[1.5rem] lg:text-[2rem] xl:text-[2.2rem] font-bold max-md:text-center'>Accessible Approachable Accountable</h1>
          <p className='text-secondary text-[1rem] sm:text-[1.1rem] lg:text-[1.3rem] mt-[1rem] lg:leading-9'>
            Eazy Refund Tax holds the team of highly experienced and specialized professionals who have more than a decade of experience working in the industry. All the people working at Eazy Refund Tax are efficient in business book keeping, taxation, US individual taxations and payroll services. We strive to provide highly professional taxation services in the best possible way.
          </p>
        </div>
      </div>
      <div className='flex justify-center gap-[2rem] ml-0 xl:ml-[-2.5rem] max-lg:hidden'>
        <div className=''>
          <img src={main3} alt="" />
        </div>
        <div className=''>
          <img src={main4} alt="" />
        </div>
      </div>
    </div>
  )
}
