import React, { useEffect } from 'react'
import contact from '../assets/contact.svg'
// import GoogleMapReact from 'google-map-react';
import { Icon } from '@iconify/react';
import ReCAPTCHA from "react-google-recaptcha";

export default function ContactUs() {
   // const center = {
   //    lat: 17.46736071585195,
   //    lng: 78.38357610573705,
   // };
   // const zoom = 12;

   const SITE_KEY = "6LevqqooAAAAABBtChk1xEYRPKK8sAE_0HWFTWFn";

   useEffect(() => {
      window.scrollTo({
         top: 0,
         behavior: 'smooth',
      })
   }, [])

   return (
      <div className='px-[1rem] sm:px-[3rem] md:max-lg:px-[1rem] lg:px-[3rem] xl:px-[5rem] mb-[2rem]'>
         <div className='flex md:flex-row flex-col items-center justify-between w-full max-md:gap-[1rem] 
      my-[1.5rem] sm:my-[2rem] md:my-0'>
            <div className='w-full md:w-[40%]'>
               <h1 className='text-primary text-[2.2rem] lg:text-[2.5rem] max-md:text-center font-bold'>
                  Contact Us
               </h1>
               <form>
                  <script src="https://oneclick.uat.payglocal.in/simple.js" data-pb-id="pb_BQ2nN4EazUBL" >
                  </script>
               </form>
               <p className='text-secondary text-[1rem] sm:text-[1.1rem] lg:text-[1.3rem] max-md:text-center 
        mt-[1rem] lg:leading-9'>
                  Online tax calculators or tax preparation software can help you enter your financial information and provide a preliminary estimate of your tax refund.
               </p>
            </div>
            <div className='w-[95%] sm:w-[80%] md:w-[50%] xl:w-[45%]'>
               <img src={contact} alt="" />
            </div>
         </div>
         <div className='w-full flex flex-col md:flex-row md:justify-between max-md:items-center max-md:gap-[1.5rem] my-[2rem]'>
            <div className='flex flex-col gap-[0.5rem] md:gap-[1rem] w-full md:w-[48%] lg:w-[45%] xl:w-[40%]'>
               <h1 className='text-primary text-[1.1rem] sm:text-[1.8rem] lg:text-[2.3rem] font-bold'>
                  Get in Touch
               </h1>
               <p className='text-secondary text-[1rem] sm:text-[1.1rem] lg:text-[1.3rem]
        lg:leading-9'>
                  We appreciate each customer and we are proud that 100% of clients come back to work with us again.
               </p>
               <div className='flex flex-col gap-[0.6rem] mt-[0.5rem]'>
                  <div className='flex items-center gap-[0.5rem] text-primary font-[500]'>
                     <Icon icon="mdi-light:phone" className='text-[1.5rem]' />
                     <h2 className='text-[1rem] sm:text-[1.1rem]'>
                        +1 (909)-999-6214, +917386670536
                     </h2>
                  </div>
                  <div className='flex items-center gap-[0.5rem] text-primary font-[500]'>
                     <Icon icon="streamline:mail-send-email-send-email-paper-airplane" className='text-[1.5rem]' />
                     <h2 className='text-[1rem] sm:text-[1.1rem]'>
                        info@eazyrefundtax.com
                     </h2>
                  </div>
               </div>
               <span className='h-[2px] bg-secondary/[0.3] my-[1rem]'></span>
               <div>
                  <h2 className='text-[1.1rem] sm:text-[1.5rem] text-primary font-bold'>
                     Office Address
                  </h2>
                  <div className='my-[1rem] grid grid-cols-2 gap-y-[1rem]'>
                     <h2 className='text-black text-[0.9rem] font-[500] sm:text-[1rem] lg:text-[1.2rem] '>
                        CORPORATE OFFICE
                     </h2>
                     <div>
                        <h2 className='text-black text-[1rem] font-[500] sm:text-[1.1rem] lg:text-[1.3rem]'>
                           EAZY REFUND CONSULTANCY LLP
                        </h2>
                        <p className='text-black text-[0.9rem] font-[500] sm:text-[1.1rem] lg:text-[1.1rem] lg:leading-9'>
                           Plot No.1148, 6th Floor Siri Heights, Khanampet (V), Serlingampally (M) NA Hyderabad Hyderabad Telangana 500033
                        </p>
                     </div>
                     <h2 className='text-black text-[0.9rem] font-[500] sm:text-[1rem] lg:text-[1.2rem] '>
                        USA OFFICE
                     </h2>
                     <p className='text-black text-[0.9rem] font-[500] sm:text-[1.1rem] lg:text-[1.1rem] lg:leading-9'>
                        2450 S UNIVERSITY BLVD APT 302 DENVER, CO 80201
                     </p>
                  </div>
                  {/* <div className='h-[260px] w-full my-[1rem]'>
                     <GoogleMapReact
                        bootstrapURLKeys={{ key: "" }}
                        defaultCenter={center}
                        defaultZoom={zoom}
                     >
                     </GoogleMapReact>
                  </div> */}
               </div>
            </div>
            <div className='flex flex-col gap-[0.5rem] md:gap-[1rem] w-full md:w-[48%] lg:w-[45%] xl:w-[40%]'>
               <h1 className='text-primary text-[1.1rem] sm:text-[1.8rem] lg:text-[2.3rem] font-bold'>
                  You Can Mail Us
               </h1>
               <p className='text-secondary text-[1rem] sm:text-[1.1rem] lg:text-[1.3rem]
        lg:leading-9'>
                  We are always looking for a next great project.
               </p>
               <form className='flex flex-col gap-[1rem] bg-none sm:bg-[#EBEAED] bg-opacity-50 rounded-[10px] px-[0rem] xs:max-sm:px-[1rem] sm:px-[2rem] py-[1rem] sm:py-[2rem] mt-[1rem]'>
                  <div className='flex flex-col gap-[0.4rem]'>
                     <label htmlFor="name" className='text-[1rem] sm:text-[1.2rem] font-[500]'>Name</label>
                     <input
                        className='py-[0.5rem] px-[1rem] text-[1rem] border border-[#767D8666] rounded-[4px] outline-none'
                        type="text"
                        placeholder='Enter Your Name' />
                     {/* <p>Error Message</p> */}
                  </div>
                  <div className='flex flex-col gap-[0.5rem]'>
                     <label htmlFor="email" className='text-[1rem] sm:text-[1.2rem] font-[500]'>Email</label>
                     <input
                        className='py-[0.5rem] px-[1rem] text-[1rem] border border-[#767D8666] rounded-[4px] outline-none'
                        type="email"
                        placeholder='Enter Your Email' />
                  </div>
                  <div className='flex flex-col gap-[0.5rem]'>
                     <label htmlFor="email" className='text-[1rem] sm:text-[1.2rem] font-[500]'>Message</label>
                     <textarea
                        className='px-[1rem] text-[1rem] border border-[#767D8666] rounded-[4px] outline-none'
                        type="email"
                        placeholder='Enter Your Message here...'
                        rows={5}
                     />
                  </div>
                  <div className='flex flex-col gap-[0.5rem]'>
                     <ReCAPTCHA className='mt-[0.2rem] sm:mt-[0.5rem] overflow-auto'
                        sitekey={SITE_KEY}
                     />
                  </div>
                  <div className='flex justify-end'>
                     <button className='py-[0.5rem] px-[2rem] sm:px-[3rem] bg-primary text-white rounded-[6px] 
              mt-[1.5rem] font-[500]'
                        type="submit"
                     >
                        Submit
                     </button>
                  </div>
               </form>
            </div>
         </div>
      </div>
   )
}

